import { Box, Typography } from '@mui/material'
import React from 'react'

const Breadcrumbs = (props) => {
  return (
    <Box sx={{display: "flex", mt:2,}}>
      <Typography fontSize={11} sx={{mr:2, "&:hover": {textDecoration: "underline", textDecorationColor: "#000000", cursor: "pointer",}}}>home</Typography>
      <Typography fontSize={11} sx={{mr:2, "&:hover": {textDecoration: "underline", textDecorationColor: "#000000", cursor: "pointer",}}}>small appliances</Typography>
      <Typography fontSize={11} sx={{mr:2, "&:hover": {textDecoration: "underline", textDecorationColor: "#000000", cursor: "pointer",}}}>toasters</Typography>
    </Box>
  )
}

export default Breadcrumbs