import { TextField } from '@mui/material';
import React, { useState } from 'react'

const Search = (props) => {

  const [query, setQuery] = useState("");

  return (
    <TextField
      fullWidth
      label="Search"
      onChange={(e) => setQuery(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          setQuery("");
        }
      }}
      variant="outlined"
      value={query}
    />
  )
}

export default Search