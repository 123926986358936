import { Box, Button, ButtonBase, Container, IconButton, List, ListItem, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../modules/Breadcrumbs'
import products from '../../data/products.json'
import { adjustColorForWhiteText } from '../../utils/adjustColorForWhiteText'
import { getMaterialDarkColor } from '../../utils/getMaterialDarkColor'

const Product = (props) => {

  const [currentProduct, setCurrentProduct] = useState(products[0])
  const [ checkoutText, setCheckoutText ] = useState("Checkout")

  useEffect(() => {
    setCheckoutText("Checkout")
  }, [currentProduct])

  return (
    <Container>
      <Breadcrumbs />
      <Box sx={{display: "flex", flexWrap: "wrap",}}>
        <Box sx={{flex: 1,}}>
          <model-viewer
            ar
            ar-modes="webxr scene-viewer quick-look"
            camera-controls
            camera-orbit="150deg 90deg"
            component="model-viewer"
            disable-zoom
            environment-image=""
            poster=""
            shadow-intensity="1"
            src={"/assets/glb/" + currentProduct.file}
            style={{
              height: "100%",
              minHeight: "500px",
              minWidth: 350,
              width: "100%",
            }}
            sx={{display: "flex", flex: 1,}}
            touch-action="pan-y"
          ></model-viewer>
        </Box>
        <Box sx={{display: "flex", flex: 1, minWidth: 350,}}>
          <Box sx={{p:2,}}>
            <Typography component="h1" fontWeight={500} variant="h4">{currentProduct?.name}</Typography>
            <Box sx={{my:1,}}>
              <Typography color="#797979" component="h2" variant="h6" sx={{lineHeight: 1,}}>Available colors</Typography>
              <Box sx={{mt:1,}}>
                {
                  products.map(product => (
                    <Tooltip title={product?.color_short}>
                      <IconButton
                        key={product.sku}
                        onClick={() => {setCurrentProduct(product)}}
                        sx={{
                          backgroundColor: product?.swatch,
                          backgroundImage: product?.swatch_image ? `url(/assets/png/${product.swatch_image})` : null,
                          backgroundPosition: "center center",
                          height: 30,
                          mr:1,
                          width: 30,
                          "&:hover": {
                            backgroundColor: getMaterialDarkColor(product?.swatch),
                            opacity: .8,
                          }
                        }}
                      >
                      </IconButton>
                    </Tooltip>
                  ))
                }
              </Box>
            </Box>
            <Typography component="h2" fontWeight={500} variant="h5">${currentProduct?.price}</Typography>
            <Box sx={{my: 2,}}>
              <Typography color="#797979" component="h2" variant="h6" sx={{lineHeight: 1,}}>{currentProduct?.color}</Typography>
              <Typography color="#797979" component="h2" variant="h6" sx={{lineHeight: 1,}}>{currentProduct?.description}</Typography>
            </Box>
            <Button
              fullWidth
              onClick={() => {setCheckoutText("It works!")}}
              variant="contained"
              sx={{
                backgroundColor: currentProduct.swatch,
                color: adjustColorForWhiteText(currentProduct.swatch),
                "&:hover": {
                  backgroundColor: getMaterialDarkColor(currentProduct.swatch),
                }
              }}
            >
              {checkoutText}
            </Button>
            <Box sx={{mt:2,}}>
              <Typography>{currentProduct?.romance}</Typography>
            </Box>
            <List>
              {
                currentProduct.features.map(feature => (
                  <ListItem key={feature}>{feature}</ListItem>
                ))
              }
            </List>
            <Box sx={{alignItems: "center", display: "flex",}}>
              <Typography>Product Code:</Typography>
              <Typography fontWeight={600} sx={{ml:1,}}>{currentProduct.sku}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default Product