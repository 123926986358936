import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from '../themes/ThemeDefault';
import Header from '../components/layouts/Header';
import Product from '../components/layouts/Product';
import MailingList from '../components/layouts/MailingList';
import Footer from '../components/layouts/Footer';

const App = () => {
  return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box>
          <Header/>
          <Product />
          <Box sx={{mt:2,}}>
            <MailingList />
          </Box>
          <Footer />
        </Box>
      </ThemeProvider>
  );
}

export default App