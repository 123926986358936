import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, Box, ButtonBase, IconButton, Toolbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Search from '../modules/Search'

const Header = (props) => {

  const [ isTablet, setIsTablet ] = useState(window.innerWidth <= 1164);
  const [ isSearchVisible, setIsSearchVisible ] = useState(false);

  // Use the useEffect hook to update the isMobile & isTabletd state when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth <= 1164); // Adjust the screen width threshold
    }
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box>
      <AppBar elevation={0} position="static">
        <Toolbar sx={{height: 120,}}>
          <Box sx={{display: "flex", flex: 1, flexDirection: "column"}}>
            <Box sx={{alignItems: "center", display: "flex", flex: 1, flexWrap: "wrap",}}>
              {
                !isTablet ? (
                  <Box sx={{display: "flex", flex: 1, justifyContent: "flex-start", flexWrap: "wrap",}}>
                    <ButtonBase sx={{mr:3, "&:hover": {textDecoration: "underline", textDecorationColor: "#ffffff"}}}>
                      <Typography fontSize={12}>Smeg in the world</Typography>
                    </ButtonBase>
                    <ButtonBase sx={{mr:3, "&:hover": {textDecoration: "underline", textDecorationColor: "#ffffff"}}}>
                      <Typography fontSize={12} sx={{mr:3,}}>Sustainability</Typography>
                    </ButtonBase>
                  </Box>
                ) : (
                  <Box sx={{display: "flex", flex: 1, justifyContent: "flex-start", flexWrap: "wrap",}}>
                    <IconButton>
                      <MenuIcon sx={{color: "#ffffff"}} />
                    </IconButton>
                  </Box>
                )
              }
              <Box sx={{display: "flex", flex: 1, flexWrap: "wrap", justifyContent: "center",}}>
                <Box
                  component="img"
                  src="/assets/svg/smeg-white.svg"
                  height={39}
                  width={200}
                >
                </Box>
              </Box>
              {
                !isTablet ? (
                  <Box sx={{display: "flex", flex: 1, justifyContent: "flex-end", flexWrap: "wrap",}}>
                    <ButtonBase sx={{ml:3, "&:hover": {textDecoration: "underline", textDecorationColor: "#ffffff"}}}>
                      <Typography fontSize={12}>Contact</Typography>
                    </ButtonBase>
                    <ButtonBase sx={{ml:3, "&:hover": {textDecoration: "underline", textDecorationColor: "#ffffff"}}}>
                      <Typography fontSize={12}>Customer service</Typography>
                    </ButtonBase>
                  </Box>
                ) : (
                  <Box sx={{alignItemsCenter: "center", display: "flex", flex: 1, justifyContent: "flex-end", flexWrap: "wrap",}}>
                    <IconButton onClick={() => {isSearchVisible ? setIsSearchVisible(false) : setIsSearchVisible(true)}}>
                      {
                        isSearchVisible ? 
                          <CloseIcon sx={{color: "#ffffff"}} /> :
                          <SearchIcon sx={{color: "#ffffff"}} />
                      }
                    </IconButton>
                  </Box>
                )
              }
            </Box>
            {
              !isTablet ? (
                <Box sx={{alignItems: "center", display: "flex", mt:2,}}>
                  <Box sx={{display: "flex", flex: 1, justifyContent: "flex-start",}}>
                    <ButtonBase sx={{mr:4, "&:hover": {textDecoration: "underline", textDecorationColor: "#ffffff"}}}>
                      <Typography sx={{textTransform: "uppercase",}}>Major Appliances</Typography>
                    </ButtonBase>
                    <ButtonBase sx={{mr:4, "&:hover": {textDecoration: "underline", textDecorationColor: "#ffffff"}}}>
                      <Typography sx={{textTransform: "uppercase",}}>Small Appliances</Typography>
                    </ButtonBase>
                    <ButtonBase sx={{mr:4, "&:hover": {textDecoration: "underline", textDecorationColor: "#ffffff"}}}>
                      <Typography sx={{textTransform: "uppercase",}}>Special Products</Typography>
                    </ButtonBase>
                    <ButtonBase sx={{mr:4, "&:hover": {textDecoration: "underline", textDecorationColor: "#ffffff"}}}>
                      <Typography sx={{textTransform: "uppercase",}}>Aesthetic Lines</Typography>
                    </ButtonBase>
                    <ButtonBase sx={{mr:4, "&:hover": {textDecoration: "underline", textDecorationColor: "#ffffff"}}}>
                      <Typography sx={{textTransform: "uppercase",}}>Inspiration</Typography>
                    </ButtonBase>
                  </Box>
                  <Box sx={{alignItems: "center", display: "flex", justifyContent: "flex-end",}}>
                    <IconButton onClick={() => {isSearchVisible ? setIsSearchVisible(false) : setIsSearchVisible(true)}}>
                      {
                        isSearchVisible ? 
                        <CloseIcon sx={{color: "#ffffff"}} /> :
                        <SearchIcon sx={{color: "#ffffff"}} />
                      }
                    </IconButton>
                    <IconButton>
                      <MenuIcon sx={{color: "#ffffff"}} />
                    </IconButton>
                  </Box>
                </Box>
              ) : (
                <Box sx={{alignItems: "center", display: "flex", mt:2,}}>
                  <Box sx={{display: "flex", flex: 1, justifyContent: "space-around",}}>
                    <ButtonBase>
                      <Typography sx={{textTransform: "uppercase", "&:hover": {textDecoration: "underline", textDecorationColor: "#ffffff"}}}>Products</Typography>
                    </ButtonBase>
                    <ButtonBase>
                      <Typography sx={{textTransform: "uppercase", "&:hover": {textDecoration: "underline", textDecorationColor: "#ffffff"}}}>Aesthetic Lines</Typography>
                    </ButtonBase>
                    <ButtonBase>
                      <Typography sx={{textTransform: "uppercase", "&:hover": {textDecoration: "underline", textDecorationColor: "#ffffff"}}}>Inspiration</Typography>
                    </ButtonBase>
                  </Box>
                </Box>
              )
            }
          </Box>
        </Toolbar>
      </AppBar>
      {
        isSearchVisible ?
          <Box sx={{ p:2, backgroundColor: "#ffffff"}}> 
            <Search />
          </Box>
          : null
      }
    </Box>
  )
}

export default Header