import { Box, Container, Typography } from '@mui/material'
import React from 'react'

const Footer = (props) => {
  return (
    <Box sx={{backgroundColor: "#000000", py:5,}}>
      <Container>
        <Box
          component="img"
          src="/assets/svg/smeg-white.svg"
          width={200}
          height={40}
        >
        </Box>
        <Box sx={{mt:3,}}>
          <Box sx={{display: "flex", flexWrap: "wrap",}}>
            <Box sx={{minWidth: 350, mt:3,}}>
              <Typography sx={{color: "#baa483", textTransform: "uppercase",}}>About Smeg</Typography>
              <Box sx={{mt: 3,}}>
                  <Typography sx={{color: "#ffffff", "&:hover": {textDecoration: "underline", textDecorationColor: "#ffffff", cursor: "pointer",},}}>The Smeg Group</Typography>
                  <Typography sx={{color: "#ffffff", "&:hover": {textDecoration: "underline", textDecorationColor: "#ffffff", cursor: "pointer",},}}>Sustainability</Typography>
                  <Typography sx={{color: "#ffffff", "&:hover": {textDecoration: "underline", textDecorationColor: "#ffffff", cursor: "pointer",},}}>News</Typography>
                  <Typography sx={{color: "#ffffff", "&:hover": {textDecoration: "underline", textDecorationColor: "#ffffff", cursor: "pointer",},}}>Contacts</Typography>
              </Box>
            </Box>
            <Box sx={{minWidth: 350, mt:3}}>
              <Typography sx={{color: "#baa483", textTransform: "uppercase",}}>Follow Us On</Typography>
              <Box sx={{mt: 3,}}>
                  <Typography sx={{color: "#ffffff", "&:hover": {textDecoration: "underline", textDecorationColor: "#ffffff", cursor: "pointer",},}}>Facebook</Typography>
                  <Typography sx={{color: "#ffffff", "&:hover": {textDecoration: "underline", textDecorationColor: "#ffffff", cursor: "pointer",},}}>Instagram</Typography>
                  <Typography sx={{color: "#ffffff", "&:hover": {textDecoration: "underline", textDecorationColor: "#ffffff", cursor: "pointer",},}}>LinkedIn</Typography>
                  <Typography sx={{color: "#ffffff", "&:hover": {textDecoration: "underline", textDecorationColor: "#ffffff", cursor: "pointer",},}}>YouTube</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{mt:3,}}>
          <Box sx={{display: "flex", flexWrap: "wrap",}}>
            <Typography fontSize={12} fontWeight={600} sx={{color: "#9b9b9b", textTransform: "uppercase", mr:2, "&:hover": {textDecoration: "underline", textDecorationColor: "#9b9b9b", cursor: "pointer",}}}>Cookie policy</Typography>
            <Typography fontSize={12} fontWeight={600} sx={{color: "#9b9b9b", textTransform: "uppercase", mr:2, "&:hover": {textDecoration: "underline", textDecorationColor: "#9b9b9b", cursor: "pointer",}}}>Legal</Typography>
            <Typography fontSize={12} fontWeight={600} sx={{color: "#9b9b9b", textTransform: "uppercase", mr:2, "&:hover": {textDecoration: "underline", textDecorationColor: "#9b9b9b", cursor: "pointer",}}}>Disclaimer</Typography>
            <Typography fontSize={12} fontWeight={600} sx={{color: "#9b9b9b", textTransform: "uppercase", mr:2, "&:hover": {textDecoration: "underline", textDecorationColor: "#9b9b9b", cursor: "pointer",}}}>Privacy policy</Typography>
            <Typography fontSize={12} fontWeight={600} sx={{color: "#9b9b9b", textTransform: "uppercase", mr:2, "&:hover": {textDecoration: "underline", textDecorationColor: "#9b9b9b", cursor: "pointer",}}}>Accessibility Declaration</Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Footer