export function adjustColorForWhiteText(hexColor) {
    // Convert hex to RGB
    var r = parseInt(hexColor.substring(1, 3), 16);
    var g = parseInt(hexColor.substring(3, 5), 16);
    var b = parseInt(hexColor.substring(5, 7), 16);

    // Calculate luminance
    var luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Check if luminance is less than 0.5 (dark color)
    // If so, return a lighter color, otherwise, return a darker color
    if (luminance < 0.5) {
        return "#ffffff"; // White text for dark background
    } else {
        return "#000000"; // Black text for light background
    }
}