import { Box, Button, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'

const MailingList = (props) => {

  const [ emailAddress, setEmailAddress ] = useState("");

  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "#f3ece0",
        display: "flex",
        flex: 1,
        flexWrap: "wrap",
        justifyContent: "space-between",
        py:3,
      }}
    >
      <Box sx={{display: "flex", flexDirection: "column", flex: 1, minWidth: 350, p:2,}}>
        <Typography fontWeight={600} variant="h5">Subscribe to newsletter</Typography>
        <Typography>Subscribe to Smeg's Newsletter and stay up to date with all the latest news and events.</Typography>
      </Box>
      <Box sx={{alignItems: "center", display: "flex", flex: 1, p:2, minWidth: 350,}}>
        <TextField
          fullWidth
          size="small"
          label="Email Address"
          variant="outlined"
          onChange={(e) => setEmailAddress(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setEmailAddress("");
            }
          }}
          value={emailAddress}
          sx={{backgroundColor: "white", maxWidth: 500, mr:2,}}
        />
        <Button onClick={() => {setEmailAddress("")}} variant="contained" sx={{minWidth: 100,}}>Subscribe</Button>
      </Box>
    </Box>
  )
}

export default MailingList