export function getMaterialDarkColor(hexColor) {
  // Convert hex to RGB
  var r = parseInt(hexColor.substring(1, 3), 16);
  var g = parseInt(hexColor.substring(3, 5), 16);
  var b = parseInt(hexColor.substring(5, 7), 16);

  // Darken the color by 20%
  var darkR = Math.max(0, Math.round(r * 0.8));
  var darkG = Math.max(0, Math.round(g * 0.8));
  var darkB = Math.max(0, Math.round(b * 0.8));

  // Convert RGB to hex
  var darkHexColor = "#" + (darkR < 16 ? "0" : "") + darkR.toString(16) +
    (darkG < 16 ? "0" : "") + darkG.toString(16) +
    (darkB < 16 ? "0" : "") + darkB.toString(16);

  return darkHexColor;
}