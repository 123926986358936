import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyC_P0LjUZQNzxydRlRI0wVJy4Fu5ALS9ak",
  authDomain: "cadsoft-smeg.firebaseapp.com",
  projectId: "cadsoft-smeg",
  storageBucket: "cadsoft-smeg.appspot.com",
  messagingSenderId: "371183051557",
  appId: "1:371183051557:web:9e4ce09228b57a9c6d70cc"
};

const app = initializeApp(firebaseConfig);
if (process.env.NODE_ENV === "developement") {
  console.log("Firebase Initialized:", app);
}